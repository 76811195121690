/** @jsx jsx **/
// @ts-ignore
import { jsx, Container, Box, BaseStyles, Heading, Text } from 'theme-ui'
import Layout from '~/components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import MarkdownIt from '~/components/MarkdownIt'
import theme from '~/gatsby-plugin-theme-ui'
import { GatsbyImageType } from '~/@types/models'
import HeroImage from '~/components/hero-image'
import LaShowroomOpenings from '~/containers/la-showroom-openings'
import HomepageHeader from '~/components/homepage/header'
import HeadingV2 from '~/componentsV2/heading'

type QueryResult = {
  pageData: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      description: string
      address: {
        line1: string
        line2: string
      }
    }
    html: string
  }
  detailImage: GatsbyImageType
  detailImageMobile: GatsbyImageType
}

const ShowroomPage = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "showroom" } }) {
        frontmatter {
          headerVariant
          header {
            title
            description
            image {
              ...heroImage
            }
            imageMobile {
              ...mobileHeroImage
            }
          }
          description
          address {
            line1
            line2
          }
        }
        html
      }
      detailImage: file(relativePath: { eq: "los-angeles-showroom-page-rug-wall-desktop.jpg" }) {
        ...heroImage
      }
      detailImageMobile: file(
        relativePath: { eq: "los-angeles-showroom-page-rug-wall-mobile.jpg" }
      ) {
        ...mobileHeroImage
      }
    }
  `)

  const { detailImage, detailImageMobile } = query

  const {
    html: content,
    frontmatter: { header, description },
  } = query.pageData

  return (
    <Layout title={header.title} description={header.description}>
      <HeroImage
        images={{
          default: header.image,
          mobile: header.imageMobile,
        }}
        height="primary"
      >
        <Container
          sx={{
            textAlign: [null, 'center'],
          }}
        >
          {/* <HeadingV2
            level="1"
            sx={{
              color: 'inherit',
            }}
          >
            {header.title}
          </HeadingV2> */}
          <Container
            sx={{
              maxWidth: 576,
            }}
          >
            <Text
              variant="regular"
              sx={{
                color: 'inherit',
                display: ['none', 'block'],
              }}
            >
              {header.description}
            </Text>
          </Container>
        </Container>
      </HeroImage>
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Text
          sx={{
            display: ['block', null, 'none'],
            mb: [theme.space.md1],
          }}
          variant="regular"
        >
          {header.description}
        </Text>
        <Box mb={[theme.space.l, null, theme.space.xl]}>
          <BaseStyles
            sx={{
              h1: {
                mb: 25,
                textTransform: `uppercase`,
                fontSize: [`17px`, null, `32px`],
              },
              '.gatsby-resp-image-wrapper': {
                my: [50, null, 100],
              },
              p: {
                color: `#666666`,
                fontSize: [`12px`, null, `16px`],
              },
              blockquote: {
                textAlign: `center`,
                mt: `40px`,
                mb: `40px`,
                a: {
                  color: '#2B2B2B',
                  textDecoration: 'none',
                  borderBottom: '1px solid #2B2B2B',
                  paddingBottom: '5px',
                  fontSize: [`10px`, null, `16px`],
                  ':visited': {
                    color: '#2B2B2B',
                  },
                },
              },
            }}
          >
            <MarkdownIt content={content} />
          </BaseStyles>
        </Box>
      </Container>
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Box mb={[theme.space.l, null, theme.space.xl]}>
          <BaseStyles
            sx={{
              '.gatsby-resp-image-wrapper': {
                my: [50, null, 100],
              },
            }}
          >
            <Text
              variant="regular"
              sx={{
                color: 'inherit',
                display: ['none', 'block'],
              }}
            >
              {description}
            </Text>
          </BaseStyles>
        </Box>
        <LaShowroomOpenings altLayout={true} />
      </Container>
      <HomepageHeader
        images={{
          default: detailImage,
          mobile: detailImageMobile,
        }}
        infoContainerSx={{
          sx: {
            marginX: [null, null, '50px'],
            marginLeft: ['15px', null, null],
            textAlign: [null, 'left'],
          },
        }}
      />
    </Layout>
  )
}

export default ShowroomPage
